<script>
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      categories: {},
      activeCategoryFilter: 'all',
      items: [],
    }
  },

  computed: {
    filteredItems() {
      if (this.activeCategoryFilter.toLowerCase() === this.categories.all) {
        return this.items
      }
      return this.items.filter(
        (item) => item.category === this.categories[this.activeCategoryFilter],
      )
    },
  },
  created() {
    if (this.defaultActiveFilter) {
      this.activeCategoryFilter = this.defaultActiveFilter
    }
  },

  mounted() {
    this.$watch('$refs.filter.activeCategory', this.filterCaseStudies)
  },
  methods: {
    filterCaseStudies(newActiveFilter, oldActiveFilter) {
      if (newActiveFilter !== oldActiveFilter) {
        this.activeCategoryFilter = newActiveFilter
        this.date = new Date().getTime()
      }
    },
  },
})
</script>
