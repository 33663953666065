<template>
  <App>
    <main class="main">
      <Header />
      <LandingPageHero>
        <template #heroContent>
          <h1 class="hero__description heading">Hi there!</h1>
          <h2 class="hero__description heading">
            Here are some useful apps we’ve
            <strong>developed in our free time</strong>
          </h2>
        </template>
        <template #social>
          <hero-social />
        </template>
      </LandingPageHero>
      <Applications />
      <div class="h-margin-top-10 h-margin-bottom-10 h-align-center">
        <h2 class="heading h2 -color-accent">Trouble with these?</h2>
        <p>
          Just send us an email at:
          <a href="mailto:support@graffino.com" class="link h-font-weight-bold"
            >support@graffino.com</a
          >
        </p>
      </div>
      <Footer />
    </main>
  </App>
</template>

<script>
import App from '~/components/App.vue'
import Footer from '~/components/Footer.vue'
import Header from '~/components/BlogHeader.vue'
import LandingPageHero from '~/components/LandingPageHero.vue'
import HeroSocial from '~/components/HeroSocial'
import Applications from '~/components/Applications.vue'

export default {
  meta: {
    title: 'Apps - Graffino • Web Development Experts',
    keywords:
      'software development, apps, extensions, browser extension, alfred extensions, safari extensions, wordpress plugins, web tools',
    indexed: true,
  },
  components: {
    App,
    Footer,
    LandingPageHero,
    Header,
    HeroSocial,
    Applications,
  },
  data() {
    return {
      title: 'Apps - Graffino • Web Development Experts',
      keywords:
        'software development, apps, extensions, browser extension, alfred extensions, safari extensions, wordpress plugins, web tools',
      description:
        'Here are some usefull apps we developed in our free time. Simple bits to make your life better. Enjoy :).',
      social: 'apps-landing',
    }
  },
  head: function () {
    return {
      title: this.title,

      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.description,
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.keywords,
        },

        {
          hid: 'og:title',
          property: 'og:title',
          content: this.title,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.description,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content:
            'https://graffino.com/' +
            require(`~/assets/images/${this.social}.jpg`),
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `https://graffino.com${this.$route.path}`,
        },
        {
          hid: 'twitter:title',
          property: 'twitter:title',
          content: this.title,
        },
        {
          hid: 'twitter:description',
          property: 'twitter:description',
          content: this.description,
        },
        {
          hid: 'twitter:image',
          property: 'twitter:image',
          content:
            'https://graffino.com/' +
            require(`~/assets/images/${this.social}.jpg`),
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `https://graffino.com${this.$route.path}`,
        },
      ],
    }
  },
}
</script>
