<template>
  <div class="filter">
    <span class="filter__intro">Filter</span>
    <ul class="filter__category-list">
      <li
        v-for="categoryKey in categoriesKeys"
        :key="categoryKey"
        :class="toggleActive(categoryKey)"
        class="filter__category"
      >
        <a
          href="#"
          class="filter__category-inner"
          @click.prevent="handleCategoryChange(categoryKey)"
          >{{ categories[categoryKey] }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CategoryFilter',
  props: {
    categories: {
      type: Object,
      required: true,
    },
    defaultActive: {
      type: String,
      required: false,
      default: 'all',
    },
  },
  data() {
    return {
      activeCategory: '',
    }
  },
  computed: {
    categoriesKeys() {
      return Object.keys(this.categories)
    },
  },
  created() {
    this.activeCategory = this.defaultActive
  },
  methods: {
    handleCategoryChange(categoryKey) {
      this.activeCategory = categoryKey
    },
    toggleActive(categoryKey) {
      return categoryKey === this.activeCategory ? 'is-active' : ''
    },
  },
}
</script>
