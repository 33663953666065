<template>
  <section id="hero" class="hero hero--landing">
    <div class="hero__content">
      <slot name="heroContent">
        <p class="hero__introduction">Here at</p>
        <img
          :src="require('~/assets/images/logo-white.svg')"
          class="hero__logo"
          alt="graffino logo"
          data-not-lazy="true"
        />
        <div class="hero__description heading">
          We`re working with startups
          <span>and enterprises to</span>
          <strong
            ><span>create profitable digital</span> products and
            ventures.</strong
          >
        </div>
        <div>We're currently updating our portfolio.</div>
      </slot>
    </div>
    <slot name="social" />
  </section>
</template>

<script>
export default {
  name: 'LandingPageHero',
}
</script>
