<template>
  <div
    class="showcase-item"
    :style="`animation-delay:${animationDelay || 0}ms`"
  >
    <LazyImgWrapper
      :image-path="`${image}`"
      :image-alt="title"
      :width="588"
      :height="650"
      :not-lazy="true"
      sizes="(min-width:1540px) 588px, (min-width:1050px) calc(40vw - 1em), (min-width:940px) calc(65vw - 1.625em), (min-width:940px) calc(79vw - 2em), calc(90vw - 0.75em)"
    />
    <template v-if="title">
      <div class="showcase-item__content-wrapper">
        <div class="showcase-item__inner" />
        <div class="showcase-item__content">
          <h3 class="showcase-item__title">
            {{ title }}
          </h3>
          <hr class="divider" />
          <p class="showcase-item__description">
            {{ description }}
          </p>
          <nuxt-link
            v-if="internalLink"
            :to="url"
            target="_blank"
            class="button"
          >
            <span class="button__inner">
              <span>{{ buttonText }}</span>
            </span>
          </nuxt-link>
          <a v-else :href="url" target="_blank" class="button">
            <span class="button__inner">
              <span>{{ buttonText }}</span>
            </span>
          </a>
          <p v-if="footerInformation" class="showcase-item__footer">
            {{ footerInformation }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CaseStudy',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    footerInformation: {
      type: String,
      required: false,
      default: null,
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Open',
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
    url: {
      type: String,
      required: false,
      default: '#',
    },
    animationDelay: {
      type: Number,
      default: 0,
    },
    internalLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
